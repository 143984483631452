/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./SuperMasterLimitUpdate.css";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import useGetSuperAdminList from "../../hooks/SuperAdmin/useGetSuperAdminList";
import useUpdateLimit from "../../hooks/useUpdateLimit";
import useGetAdminAccount from "../../hooks/useGetAdminAccount";
import { useQueryClient } from "react-query";

export default function SuperMasterLimitUpdate() {
  const uuid = localStorage.getItem("UUID");

  const queryClient = useQueryClient();

  const { data: adminData } = useGetAdminAccount();
  const { data: SuperAdminListData } = useGetSuperAdminList();
  const { mutate: updateLimit } = useUpdateLimit();
  const [data, setData] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [parentData, setParentData] = useState([]);

  useEffect(() => {
    const listData = SuperAdminListData?.data;

    if (listData) {
      listData.sort((a, b) => {
        return b.code.localeCompare(a.code);
      });
      setData(listData);
      setCompleteData(listData);
    }
  }, [SuperAdminListData]);

  useEffect(() => {
    if (adminData?.data.data[0].id === uuid) {
      setParentData(adminData?.data.data[0]);
    } else {
      setParentData(adminData?.data.data[1]);
    }
  }, [adminData, uuid]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
      setIsFirstPage(currentPage - 1 <= 3);

      setIsLastPage(false);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(data.length / itemsPerPage)) {
      paginate(currentPage + 1);
      setIsFirstPage(false);
      setIsLastPage(
        currentPage + 1 >= Math.ceil(data.length / itemsPerPage) - 2
      );
    }
  };

  function searchFilter(value) {
    const filteredData = data.filter((item) => {
      return (
        item.code.toLowerCase().includes(value.toLowerCase()) ||
        item.name.toLowerCase().includes(value.toLowerCase())
      );
    });

    setData(filteredData);
  }

  const handleAddLimit = (id, superAdminLimit) => {
    const inputElement = document.getElementById(id);
    const value = Math.floor(Number(inputElement.value));

    if (!value || value < 0 || value === "0") {
      toast.error("Please enter valid Limit");
      return;
    }

    if (value > parentData.limit) {
      toast.error(
        "You can not add balance greater than your Parent limit balance"
      );
      inputElement.value = "";
      return;
    }

    const superAdminLimitPayload = {
      user_id: id,
      acc_type: "super_admin",
      limit: Math.floor(Number(superAdminLimit)) + Math.floor(Number(value)),
      coins: Math.floor(Number(value)),
      types: "credit",
      description: "Super Admin Limit Update",
    };

    const parentLimitPayload = {
      user_id: parentData.id,
      acc_type: "admin",
      limit: Math.floor(Number(parentData.limit)) - Math.floor(Number(value)),
      coins: Math.floor(Number(value)),
      types: "debit",
      description: "Admin Limit Update",
    };

    updateLimit(parentLimitPayload, {
      onSuccess: () => {
        updateLimit(superAdminLimitPayload, {
          onSuccess: () => {
            queryClient.invalidateQueries("get-super-admin-list");
            toast.success("Limit Added Successfully");

            inputElement.value = "";
          },
          oonError: (error) => {
            console.error(error);
          },
        });
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  const handleMinusLimit = (id, superAdminLimit) => {
    const inputElement = document.getElementById(id);
    const value = Math.floor(Number(inputElement.value));

    if (!value || value < 0 || value === "0") {
      toast.error("Please enter valid Limit");
      return;
    }

    if (value > superAdminLimit) {
      toast.error("You can not minus balance greater than your balance");
      inputElement.value = "";
      return;
    }

    const superAdminLimitPayload = {
      user_id: id,
      acc_type: "super_admin",
      limit: Math.floor(Number(superAdminLimit)) - Math.floor(Number(value)),
      coins: Math.floor(Number(value)),
      types: "debit",
      description: "Super Admin Limit Update",
    };

    const parentLimitPayload = {
      user_id: parentData.id,
      acc_type: "admin",
      limit: Math.floor(Number(parentData.limit)) + Math.floor(Number(value)),
      coins: Math.floor(Number(value)),
      types: "credit",
      description: "Admin Limit Update",
    };

    updateLimit(superAdminLimitPayload, {
      onSuccess: () => {
        updateLimit(parentLimitPayload, {
          onSuccess: () => {
            queryClient.invalidateQueries("get-super-admin-list");
            toast.success("Limit Minus Successfully");

            inputElement.value = "";
          },
          oonError: (error) => {
            console.error(error);
          },
        });
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Super Admin</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/supermaster">Super Admin</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Limit Update</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content" style={{ overflowX: "auto" }}>
        <div className="container-fluid">
          <div className="col-6">
            <div className="card" style={{ width: "730px" }}>
              <div className="card-header bg-primary">
                <h5 className="text-white mb-0">Super Admin Coin Details</h5>
              </div>

              <div className="card-body">
                <div className="">
                  <div className="row">
                    <div className="col-12 d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between gap-0 text-center">
                      {/* Entries Dropdown (Left on Large Screens) */}
                      <div className="d-flex align-items-center gap-2 mb-2">
                        <label className="mb-0">Show</label>
                        <select
                          className="custom-select form-select w-auto"
                          onChange={(e) => setItemsPerPage(e.target.value)}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        <span>entries</span>
                      </div>

                      {/* Search Input (Right on Large Screens) */}
                      <div className="d-flex align-items-center gap-2 mb-2">
                        <label htmlFor="search" className="mb-0">
                          Search:
                        </label>
                        <input
                          type="text"
                          id="search"
                          className="form-control form-control-sm w-auto"
                          placeholder="Type to search..."
                          onChange={(e) => {
                            const value = e.target.value.trim();
                            if (value?.length > 0) {
                              searchFilter(value);
                            } else {
                              setData(completeData);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <table
                    className="table table-bordered table-striped table-responsive"
                    role="grid"
                  >
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Super Admin Name</th>
                        <th>Super Admin Limit</th>
                        <th>Enter Limit</th>
                        <th>My Limit: {parentData?.limit}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data?.length !== 0 ? (
                        currentItems?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {item.name} ({item.code})
                              </td>
                              <td id="limit-update1">
                                {Math.round(item.limit)}
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter Limit"
                                  min="0"
                                  id={item.id}
                                  style={{ width: "200px" }}
                                />
                              </td>
                              <td
                                className="action-btn-container"
                                id="agent-plus-minus-box1"
                              >
                                <Link
                                  className="btn btn-primary mr-2 action-btns action-button-plus-minus"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleAddLimit(item.id, item.limit);
                                  }}
                                >
                                  Add
                                </Link>
                                <Link
                                  className="btn btn-danger action-btns action-button-plus-minus"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleMinusLimit(item.id, item.limit);
                                  }}
                                >
                                  Minus
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <>
                          <tr>
                            <td colSpan={5} className="text-center p-5">
                              <h6 className="p-5">No records to display</h6>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-5">
                    <div className="dataTables_info" id="example1_info">
                      Showing {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
                      {completeData.length} entries
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example1_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className={`paginate_button page-item previous ${
                            currentPage <= 1 ? "disabled" : ""
                          }`}
                          id="example1_previous"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handlePrevious}
                          >
                            Previous
                          </a>
                        </li>
                        {[...Array(Math.ceil(data.length / itemsPerPage))].map(
                          (_, index) => {
                            const pageNumber = index + 1;
                            if (
                              pageNumber >= currentPage - 2 &&
                              pageNumber <= currentPage + 2
                            ) {
                              return (
                                <li key={index} className="page-item">
                                  <a
                                    onClick={() => paginate(pageNumber)}
                                    className={`page-link ${
                                      currentPage === pageNumber
                                        ? "active-page-link"
                                        : ""
                                    }`}
                                  >
                                    {pageNumber}
                                  </a>
                                </li>
                              );
                            }
                            return null;
                          }
                        )}

                        <li
                          className={`paginate_button page-item next ${
                            currentPage >= Math.ceil(data.length / itemsPerPage)
                              ? "disabled"
                              : ""
                          }`}
                          id="example1_next"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handleNext}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
