import { useEffect, useState, useRef } from "react";
import { io } from "socket.io-client";

const useSocketBetguru = (matchId) => {
  const [data, setData] = useState(null);
  const socketRef = useRef(null);

  useEffect(() => {
    if (matchId === 0) {
      return;
    }

    socketRef.current = io("https://spiderman.jmd10.com", {
      path: "/matchDataSocket/",
      transports: ["websocket"],
    });

    const handleConnect = () => {
      console.log("Connected to server");

      socketRef.current.emit("match_fancy", {
        match_id: matchId,
        api_type: 2,
      });

      const handleMatchData = (matchData) => {
        setData(matchData);
      };

      socketRef.current.on(`fancy_${matchId}`, handleMatchData);

      return () => {
        socketRef.current.off(`fancy_${matchId}`, handleMatchData);
      };
    };

    const handleError = (error) => {
      console.error("Socket error: ", error);
    };

    socketRef.current.on("connect", handleConnect);
    socketRef.current.on("error", handleError);

    return () => {
      socketRef.current.disconnect();
      socketRef.current.off("connect", handleConnect);
      socketRef.current.off("error", handleError);
    };
  }, [matchId]);

  return data;
};

export default useSocketBetguru;
