/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import useEditGame from "../../hooks/Games/Inplay/useEditGame";

export default function InPlayEdit() {
  const navigate = useNavigate();
  let accountType = localStorage.getItem("account");

  const { mutate: editGame } = useEditGame();

  const location = useLocation();
  const gameData = location.state;

  const [code, setCode] = useState("");
  const [scoreCode, setScoreCode] = useState("");
  const [tvCode, setTvCode] = useState("");
  const [matchName, setMatchName] = useState("");
  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");
  const [matchAPI, setMatchAPI] = useState("");
  const [tvAPI, setTvAPI] = useState("1");
  const [scoreCodeAPI, setScoreCodeAPI] = useState("1");
  const [matchType, setMatchType] = useState("");
  const [sessionAutomatic, setSessionAutomatic] = useState(true);

  useEffect(() => {
    if (gameData) {
      setCode(gameData.code);
      setScoreCode(gameData.score_code);
      setTvCode(gameData.tv_code);
      setMatchName(gameData.name);
      setTeam1(gameData.team1);
      setTeam2(gameData.team2);
      setMatchAPI(gameData.api);
      setTvAPI(gameData.tv_code_api);
      setMatchType(gameData.match_type);
      setSessionAutomatic(gameData.session_automatic);
      setScoreCodeAPI(gameData.score_code_api);
    }
  }, [gameData]);

  function handleSubmit(e) {
    e.preventDefault();

    const gameId = gameData.id;

    const payload = {
      code: code,
      name: matchName,
      match_type: matchType,
      team1: team1,
      team2: team2,
      score_code: scoreCode,
      score_code_api: scoreCodeAPI,
      tv_code: tvCode,
      api: matchAPI,
      tv_code_api: tvAPI,
      session_automatic: sessionAutomatic,
    };

    editGame(
      { gameId, payload },
      {
        onSuccess: () => {
          toast.success("Game Updated Successfully");
          navigate("/inplay");
        },
        onError: (error) => {
          toast.error("Failed to update game");
          console.log(error);
        },
      }
    );
  }

  useEffect(() => {
    if (accountType === "admin" || accountType === "power_user") {
      console.log("ok");
    } else {
      navigate("/inplay");
      toast.error("You don't have access!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Edit Game</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/inplay">In Play</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Edit Game</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <form onSubmit={handleSubmit}>
          <div className="row mb-4">
            {/* Share and Commission Form */}
            <div className="col-md-6 w-100">
              <div className="accordion" id="accordionExample2">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      id="create-button-1"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      General
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="form-group mb-2 col-lg-4">
                          <label className="form-label">Code</label>
                          <input
                            type="text"
                            id="code"
                            name="code"
                            className="form-control"
                            placeholder="Enter Code"
                            required
                            value={code}
                            onChange={(e) => {
                              setCode(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2 col-lg-4">
                          <label className="form-label">Score Code</label>
                          <input
                            type="text"
                            id="scoreCode"
                            name="scoreCode"
                            className="form-control"
                            placeholder="Enter Score Code"
                            required
                            value={scoreCode}
                            onChange={(e) => {
                              setScoreCode(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2 col-lg-4">
                          <label className="form-label">Tv Code</label>
                          <input
                            type="text"
                            id="tvCode"
                            name="tvCode"
                            className="form-control"
                            placeholder="Enter TV Code"
                            required
                            value={tvCode}
                            onChange={(e) => {
                              setTvCode(e.target.value);
                            }}
                          />
                        </div>

                        <div className="form-group mb-2 col-md-12">
                          <label className="form-label">Match Name</label>
                          <input
                            type="text"
                            id="matchName"
                            name="matchName"
                            className="form-control"
                            placeholder="Enter Match Name"
                            required
                            value={matchName}
                            onChange={(e) => {
                              setMatchName(e.target.value);
                            }}
                          />
                        </div>

                        <div className="form-group mb-2 col-md-6">
                          <label className="form-label">Team 1</label>
                          <input
                            type="text"
                            id="team1"
                            name="team1"
                            className="form-control"
                            placeholder="Enter Team1"
                            required
                            value={team1}
                            onChange={(e) => {
                              setTeam1(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2 col-md-6">
                          <label className="form-label">Team 2</label>
                          <input
                            type="text"
                            id="team2"
                            name="team2"
                            className="form-control"
                            placeholder="Enter Team2"
                            required
                            value={team2}
                            onChange={(e) => {
                              setTeam2(e.target.value);
                            }}
                          />
                        </div>

                        <div className="form-group mb-2 col-md-6">
                          <label className="form-label">Match API</label>
                          <select
                            className="form-select"
                            id="matchAPI"
                            name="matchAPI"
                            required
                            value={matchAPI}
                            onChange={(e) => {
                              setMatchAPI(e.target.value);
                            }}
                          >
                            <option value="">Select API</option>
                            <option value="1">API 1 (Ice)</option>
                            <option value="2">API 2 (Bet)</option>
                            <option value="4">API 3 (Personal)</option>
                            <option value="0">DEMO GAME</option>
                          </select>
                        </div>
                        <div className="form-group mb-2 col-md-6">
                          <label className="form-label">TV API</label>
                          <select
                            className="form-select"
                            id="tvAPI"
                            name="tvAPI"
                            required
                            value={tvAPI}
                            onChange={(e) => {
                              setTvAPI(e.target.value);
                            }}
                          >
                            <option value="1">API 1</option>
                            <option value="2">API 2</option>
                          </select>
                        </div>

                        <div className="form-group mb-2 col-md-6">
                          <label className="form-label">Match Type</label>
                          <select
                            className="form-select"
                            id="matchType"
                            name="matchType"
                            required
                            value={matchType}
                            onChange={(e) => {
                              setMatchType(e.target.value);
                            }}
                          >
                            <option value="">Select Type</option>
                            <option value="One Day Match">One Day Match</option>
                            <option value="T20">T20</option>
                            <option value="T10">T10</option>
                            <option value="100ball">100ball</option>
                            <option value="Test">Test Match</option>
                          </select>
                        </div>
                        <div className="form-group mb-2 col-md-6">
                          <label className="form-label">Score code API</label>
                          <select
                            className="form-select"
                            id="scoreCodeAPI"
                            name="scoreCodeAPI"
                            required
                            value={scoreCodeAPI}
                            onChange={(e) => {
                              setScoreCodeAPI(e.target.value);
                            }}
                          >
                            <option value="1">1 (score247)</option>
                            <option value="2">2 (proexch)</option>
                            <option value="3">3 (cricexpo)</option>
                          </select>
                        </div>

                        <div className="form-group mb-2 col-md-6">
                          <label className="form-label">
                            Session Automatic
                          </label>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="sessionAutomatic"
                              name="sessionAutomatic"
                              checked={sessionAutomatic}
                              onChange={(e) => {
                                setSessionAutomatic(e.target.checked);
                              }}
                            />
                          </div>
                        </div>

                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
