/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import useGetUserOnlySession from "../../hooks/Games/Inplay/useGetUserOnlySession";

export default function InplaySessionListPlusMinus() {
  const location = useLocation();

  let gameData = [];
  let gameId = "";
  const accountType = localStorage.getItem("account");
  const userId = localStorage.getItem("UUID");
  const isMasterAdmin = localStorage.getItem("isMasterAdmin");
  if (location.state) {
    gameData = location.state;
    gameId = location.state.id;
  }

  const { mutate: getUserOnlySession } = useGetUserOnlySession();

  const [sessionData, setSessionData] = useState([]);

  const [userData, setUserData] = useState([]);

  const [selectAll, setSelectAll] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUserData, setSelectedUserData] = useState([]);

  const [selectAllSession, setSelectAllSession] = useState(true);
  const [selectedSession, setSelectedSession] = useState([]);
  const [selectedSessionData, setSelectedSessionData] = useState([]);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    setSelectedUsers(userData.map(() => newSelectAll));

    setSelectedUserData(newSelectAll ? userData : []);
  };

  const handleSelectAllSession = () => {
    const newSelectAll = !selectAllSession;
    setSelectAllSession(newSelectAll);

    setSelectedSession(sessionData.map(() => newSelectAll));

    setSelectedSessionData(newSelectAll ? sessionData : []);
  };

  const handleSelectUser = (index) => {
    const updatedSelection = [...selectedUsers];
    updatedSelection[index] = !updatedSelection[index];
    setSelectedUsers(updatedSelection);

    const updatedSelectedUsers = updatedSelection
      .map((isSelected, i) => (isSelected ? userData[i] : null))
      .filter(Boolean); // Remove null values

    setSelectedUserData(updatedSelectedUsers);

    setSelectAll(!updatedSelection.includes(false));
  };

  const handleSelectSession = (index) => {
    const updatedSelection = [...selectedSession];
    updatedSelection[index] = !updatedSelection[index];
    setSelectedSession(updatedSelection);

    const updatedSelectedSession = updatedSelection
      .map((isSelected, i) => (isSelected ? sessionData[i] : null))
      .filter(Boolean); // Remove null values

    setSelectedSessionData(updatedSelectedSession);

    setSelectAllSession(!updatedSelection.includes(false));
  };

  useEffect(() => {
    const payload = {
      match_id: gameId,
      logged_acc_type: accountType,
      user_id: userId,
    };

    let userExist = [];

    getUserOnlySession(payload, {
      onSuccess: (data) => {
        let filteredData = [];

        if (accountType !== "admin") {
          data.data.filter((item) => {
            if (item.view === true || item.view === 1) {
              if (item.trash === false || item.trash === null) {
                filteredData.push(item);
              }
            }
          });
        } else {
          if (isMasterAdmin === "1" || isMasterAdmin === "true") {
            filteredData = data.data;
          } else {
            filteredData = data.data.filter((item) => item.view === true || item.view === 1);
          }
        }

        data.data.forEach((element) => {
          if (!userExist.some((user) => user.id === element.user_account.id)) {
            userExist.push({
              id: element.user_account.id,
              name: element.user_account.name,
              code: element.user_account.code,
            });
          }
        });

        filteredData = filteredData.filter(
          (session, index, self) => index === self.findIndex((s) => s.session === session.session)
        );

        setUserData(userExist);
        setSessionData(filteredData);

        setSelectedUsers(userExist.map(() => true));
        setSelectedUserData(userExist);

        setSelectedSession(filteredData.map(() => true));
        setSelectedSessionData(filteredData);
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Session List Plus Minus</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/inplay">In Play</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Session List Plus Minus</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="mb-0 card-title">{gameData.name}</div>
                  <Link
                    className="btn btn-primary"
                    to={`/complete-games/all-company-report?id=${gameData.id}&team=${gameData.won_by}`}
                    state={{ selectedUserData: selectedUserData, selectedSessionData: selectedSessionData }}>
                    Show
                  </Link>
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="row align-items-start">
                  <div className="col-md-8">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>
                            <input type="checkbox" checked={selectAllSession} onChange={handleSelectAllSession} />
                          </th>
                          <th>SESSION (POST DATE)</th>
                          <th>DECLARE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sessionData.length !== 0 ? (
                          sessionData.map((session, index) => (
                            <tr key={`session-${index}`}>
                              <td className="p-3">
                                <input
                                  type="checkbox"
                                  checked={selectedSession[index]}
                                  onChange={() => handleSelectSession(index)}
                                />
                              </td>
                              <td style={{ verticalAlign: "middle" }}>{session.session}</td>
                              <td style={{ verticalAlign: "middle" }}>{session.declare}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3" className="text-center">
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-4">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>
                            <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                          </th>
                          <th>Name (CODE)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userData.length > 0 ? (
                          userData.map((user, index) => (
                            <tr key={`user-${index}`}>
                              <td className="p-3">
                                <input
                                  type="checkbox"
                                  checked={selectedUsers[index]}
                                  onChange={() => handleSelectUser(index)}
                                />
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                {user.name} ({user.code})
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2" className="text-center">
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
