/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useGetBetsData from "../../hooks/Games/Inplay/useGetBetsData";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import Select from "react-select";

export default function CompleteMatchBets() {
  const location = useLocation();
  const navigate = useNavigate();
  const UrlSearchParams = new URLSearchParams(location.search);

  let teams = location.state;

  const { mutate: getBetsData } = useGetBetsData();

  const matchId = UrlSearchParams.get("id");
  const matchType = UrlSearchParams.get("match_type");
  const accountType = localStorage.getItem("account");
  const userId = localStorage.getItem("UUID");

  const [matchBets, setMatchBets] = useState([]);
  const [allBets, setAllBets] = useState([]);
  const [users, setUsers] = useState([]);
  const [team1Total, setTeam1Total] = useState(0);
  const [team2Total, setTeam2Total] = useState(0);
  const [team3Total, setTeam3Total] = useState(0);

  useEffect(() => {
    if (matchId !== undefined && matchId !== null) {
      const payload = {
        match_id: matchId,
        logged_acc_type: accountType,
        user_id: userId,
      };

      getBetsData(payload, {
        onSuccess: (response) => {
          let userData = [];

          response.data.forEach((el) => {
            if (!userData.some((ele) => ele.id === el.user_accounts[0].id)) {
              userData.push({
                id: el.user_accounts[0].id,
                name: `${el.user_accounts[0].name}`,
                code: `${el.user_accounts[0].code}`,
              });
            }
          });

          setUsers(userData);

          calculateBets(response.data);

          setMatchBets(response.data);
          setAllBets(response.data);
        },
        onError: (error) => {
          console.log(error);
        },
      });
    } else {
      navigate("/complete-games");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateBets = (bets) => {
    let team1Bets = 0;
    let team2Bets = 0;
    let team3Bets = 0;

    if (bets.length > 0) {
      bets?.forEach((bets) => {
        if (bets.betTeam === "A" && bets.type === "lay") {
          team1Bets = team1Bets + bets.teamA_lay_amount;
          team2Bets = team2Bets + bets.teamB_lay_amount;
          team3Bets = team3Bets + bets.draw_lay_amount;
        }

        if (bets.betTeam === "A" && bets.type === "back") {
          team1Bets = team1Bets + bets.teamA_bet_amount;
          team2Bets = team2Bets + bets.teamB_bet_amount;
          team3Bets = team3Bets + bets.draw_bet_amount;
        }

        if (bets.betTeam === "B" && bets.type === "back") {
          team1Bets = team1Bets + bets.teamA_bet_amount;
          team2Bets = team2Bets + bets.teamB_bet_amount;
          team3Bets = team3Bets + bets.draw_bet_amount;
        }

        if (bets.betTeam === "B" && bets.type === "lay") {
          team1Bets = team1Bets + bets.teamA_lay_amount;
          team2Bets = team2Bets + bets.teamB_lay_amount;
          team3Bets = team3Bets + bets.draw_lay_amount;
        }

        if (bets.betTeam === "DRAW" && bets.type === "lay") {
          team1Bets = team1Bets + bets.teamA_lay_amount;
          team2Bets = team2Bets + bets.teamB_lay_amount;
          team3Bets = team3Bets + bets.draw_lay_amount;
        }

        if (bets.betTeam === "DRAW" && bets.type === "back") {
          team1Bets = team1Bets + bets.teamA_bet_amount;
          team2Bets = team2Bets + bets.teamB_bet_amount;
          team3Bets = team3Bets + bets.draw_bet_amount;
        }
      });

      setTeam1Total(Math.round(team1Bets));
      setTeam2Total(Math.round(team2Bets));
      setTeam3Total(Math.round(team3Bets));
    }
  };

  function changeUser(value) {
    if (value === "All" || value === "") {
      setMatchBets(allBets);
    } else {
      const filteredBets = allBets.filter(
        (bet) => bet.user_accounts[0].id === value
      );

      let team1Bets = 0;
      let team2Bets = 0;
      let team3Bets = 0;

      if (filteredBets.length > 0) {
        filteredBets?.forEach((bets) => {
          if (bets.betTeam === "A" && bets.type === "lay") {
            team1Bets = team1Bets + bets.teamA_lay_amount;
            team2Bets = team2Bets + bets.teamB_lay_amount;
            team3Bets = team3Bets + bets.draw_lay_amount;
          }

          if (bets.betTeam === "A" && bets.type === "back") {
            team1Bets = team1Bets + bets.teamA_bet_amount;
            team2Bets = team2Bets + bets.teamB_bet_amount;
            team3Bets = team3Bets + bets.draw_bet_amount;
          }

          if (bets.betTeam === "B" && bets.type === "back") {
            team1Bets = team1Bets + bets.teamA_bet_amount;
            team2Bets = team2Bets + bets.teamB_bet_amount;
            team3Bets = team3Bets + bets.draw_bet_amount;
          }

          if (bets.betTeam === "B" && bets.type === "lay") {
            team1Bets = team1Bets + bets.teamA_lay_amount;
            team2Bets = team2Bets + bets.teamB_lay_amount;
            team3Bets = team3Bets + bets.draw_lay_amount;
          }

          if (bets.betTeam === "DRAW" && bets.type === "lay") {
            team1Bets = team1Bets + bets.teamA_lay_amount;
            team2Bets = team2Bets + bets.teamB_lay_amount;
            team3Bets = team3Bets + bets.draw_lay_amount;
          }

          if (bets.betTeam === "DRAW" && bets.type === "back") {
            team1Bets = team1Bets + bets.teamA_bet_amount;
            team2Bets = team2Bets + bets.teamB_bet_amount;
            team3Bets = team3Bets + bets.draw_bet_amount;
          }
        });

        setTeam1Total(Math.round(team1Bets));
        setTeam2Total(Math.round(team2Bets));
        setTeam3Total(Math.round(team3Bets));
      }

      setMatchBets(filteredBets);
    }
  }

  const displayAsOpposite = (value) => {
    const numValue = Number(value);
    return numValue > 0 ? -Math.abs(numValue) : Math.abs(numValue); // Negate if positive, otherwise take absolute
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Match Bet Details</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/complete-games">Complete Games</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Match Bet Details</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-12 w-100">
            <div className="card">
              {/* /.card-header */}
              <div className="card-header">
                <div className="table">
                  <div className="col-6">
                    <table className="table table-bordered align-middle">
                      <tbody style={{ color: "black" }}>
                        <tr>
                          <th style={{ height: "47px" }}>{teams?.team1}</th>
                          <td> {displayAsOpposite(team1Total)}</td>
                        </tr>
                        <tr style={{ height: "47px" }}>
                          <th>{teams?.team2}</th>
                          <td>{displayAsOpposite(team2Total)}</td>
                        </tr>
                        {matchType === "Test" && (
                          <tr style={{ height: "47px" }}>
                            <th>Draw</th>
                            <td>{displayAsOpposite(team3Total)}</td>
                          </tr>
                        )}
                        <tr>
                          <th>Client</th>
                          <td
                            style={{
                              textAlign: "left",
                            }}
                          >
                            <Select
                              options={[
                                { value: "", label: "Select" },
                                { value: "All", label: "All" },
                                ...users.map((user) => ({
                                  value: user.id,
                                  label: `${user.code} - ${user.name}`,
                                })),
                              ]}
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={""}
                              isDisabled={false}
                              isLoading={users.length === 0 ? true : false}
                              isSearchable={true}
                              isRtl={false}
                              name="selectedUser"
                              onChange={(e) => {
                                const selected = e?.value;
                                changeUser(selected);
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* /.card-header */}

              {/* /.card-body */}
              <div className="card-body">
                <div className="table-responsive">
                  <div className="col-md-12">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th style={{ padding: ".75rem" }}>S. No.</th>
                          <th style={{ padding: ".75rem" }}>Client</th>
                          <th style={{ padding: ".75rem" }}>Rate</th>
                          <th style={{ padding: ".75rem" }}>Team</th>
                          <th style={{ padding: ".75rem" }}>Mode</th>
                          <th style={{ padding: ".75rem" }}>Amount</th>
                          <th style={{ padding: ".75rem" }}>Date & Time</th>
                          <th style={{ padding: ".75rem" }}>{teams?.team1}</th>
                          <th style={{ padding: ".75rem" }}>{teams?.team2}</th>
                          {matchType === "Test" && (
                            <th style={{ padding: ".75rem" }}>Draw</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {matchBets?.length > 0 ? (
                          matchBets.map((bets, index) => (
                            <tr key={index}>
                              <td style={{ padding: ".75rem" }}>{index + 1}</td>
                              <td style={{ padding: ".75rem" }}>
                                {bets.user_accounts[0].name} (
                                {bets.user_accounts[0].code})
                              </td>

                              {bets.betTeam === "A" ? (
                                <td style={{ padding: ".85rem" }}>
                                  {bets.type === "lay"
                                    ? bets.teamA_lay_rate
                                    : bets.teamA_back_rate}
                                </td>
                              ) : bets.betTeam === "B" ? (
                                <td style={{ padding: ".85rem" }}>
                                  {bets.type === "lay"
                                    ? bets.teamB_lay_rate
                                    : bets.teamB_back_rate}
                                </td>
                              ) : (
                                <td style={{ padding: ".85rem" }}>
                                  {bets.type === "lay"
                                    ? bets.draw_lay_rate
                                    : bets.draw_back_rate}
                                </td>
                              )}

                              {bets.betTeam === "A" ? (
                                <td style={{ padding: ".75rem" }}>
                                  {teams?.team1}
                                </td>
                              ) : bets.betTeam === "B" ? (
                                <td style={{ padding: ".75rem" }}>
                                  {teams?.team2}
                                </td>
                              ) : (
                                <td style={{ padding: ".85rem" }}>DRAW</td>
                              )}

                              {bets.type === "lay" ? (
                                <td style={{ padding: ".75rem" }}>Khai</td>
                              ) : (
                                <td style={{ padding: ".75rem" }}>Lagai</td>
                              )}

                              <td style={{ padding: ".75rem" }}>
                                {bets.amount}
                              </td>

                              <td
                                style={{ padding: ".75rem", minWidth: "160px" }}
                              >
                                {dateTimeFormatter(bets.createdAt)}
                              </td>

                              {bets.type === "lay" ? (
                                <td style={{ padding: ".75rem" }}>
                                  {Number(bets.teamA_lay_amount)}
                                </td>
                              ) : (
                                <td style={{ padding: ".75rem" }}>
                                  {Number(bets.teamA_bet_amount)}
                                </td>
                              )}

                              {bets.type === "lay" ? (
                                <td style={{ padding: ".75rem" }}>
                                  {Number(bets.teamB_lay_amount)}
                                </td>
                              ) : (
                                <td style={{ padding: ".75rem" }}>
                                  {Number(bets.teamB_bet_amount)}
                                </td>
                              )}

                              {matchType === "Test" ? (
                                bets.type === "lay" ? (
                                  <td style={{ padding: ".85rem" }}>
                                    {Number(bets.draw_lay_amount)}
                                  </td>
                                ) : (
                                  <td style={{ padding: ".85rem" }}>
                                    {Number(bets.draw_bet_amount)}
                                  </td>
                                )
                              ) : null}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={matchType === "Test" ? 10 : 9}>
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* /.card-body */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
