import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import "./sidebar.css";
import AdminSidebar from "./SiderbarAllowFeatures/AdminSidebar";
import Subadmin from "./SiderbarAllowFeatures/SubAdmin";
import MasterSidebar from "./SiderbarAllowFeatures/Master";
import SuperMasterSidebar from "./SiderbarAllowFeatures/SuperMasterSidebar";
import SuperAgentSidebar from "./SiderbarAllowFeatures/SuperAgent";
import AgentSidebar from "./SiderbarAllowFeatures/Agent.js";
import PowerUser from "./SiderbarAllowFeatures/PowerUser";
import Header from "./Header";
import AdminLTELogoImage from "../img/AdminLTELogo.png";

export default function Sidebar() {
  const [role, setRole] = useState("");
  const header = window.location.href.includes("jmd20")
    ? "JMD20"
    : window.location.href.includes("1exch")
    ? "1EXCH"
    : "JAI HO";

  useEffect(() => {
    const roleGet = localStorage.getItem("account");
    setRole(roleGet);
  }, []);

  const toggleSidebar = () => {
    let screenWidth = window.innerWidth;
    const body = document.querySelector("body");

    if (screenWidth < 992) {
      if (body.classList.contains("sidebar-collapse")) {
        body.classList.remove("sidebar-collapse");
      }
      body.classList.toggle("sidebar-open");
    } else {
      if (body.classList.contains("sidebar-open")) {
        body.classList.remove("sidebar-open");
      }
      body.classList.toggle("sidebar-collapse");
    }
  };

  const handleSidebar = () => {
    let screenWidth = window.innerWidth;
    const body = document.querySelector("body");

    if (screenWidth < 992) {
      if (body.classList.contains("sidebar-collapse")) {
        body.classList.remove("sidebar-collapse");
      }
      if (body.classList.contains("sidebar-open")) {
        body.classList.remove("sidebar-open");
      }
    } else {
      if (body.classList.contains("sidebar-open")) {
        body.classList.remove("sidebar-open");
      }
      if (body.classList.contains("sidebar-collapse")) {
        return;
      } else {
        body.classList.add("sidebar-collapse");
      }
    }
  };

  return (
    <>
      <Header toggleSidebar={toggleSidebar} />
      <aside className="main-sidebar sidebar-light main-side-bar-size bg-indigo elevation-4">
        <Link to="/dashboard" className="brand-link" onClick={handleSidebar}>
          <img
            src={AdminLTELogoImage}
            alt="AdminLTE Logo"
            className="brand-image img-circle elevation-3"
            style={{ opacity: "0.8" }}
          />
          <span className="brand-text" id="brand-text1" onClick={handleSidebar}>
            {header}
          </span>
        </Link>
        <div className="sidebar bg-light">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {role === "admin" ? (
                <AdminSidebar toggleSidebar={handleSidebar} />
              ) : null}
              {role === "super_admin" ? (
                <SuperMasterSidebar toggleSidebar={handleSidebar} />
              ) : null}
              {role === "sub_admin" ? (
                <Subadmin toggleSidebar={handleSidebar} />
              ) : null}
              {role === "master" ? (
                <MasterSidebar toggleSidebar={handleSidebar} />
              ) : null}
              {role === "super_agent" ? (
                <SuperAgentSidebar toggleSidebar={handleSidebar} />
              ) : null}
              {role === "agent" ? (
                <AgentSidebar toggleSidebar={handleSidebar} />
              ) : null}
              {role === "power_user" ? (
                <PowerUser toggleSidebar={handleSidebar} />
              ) : null}
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
}
